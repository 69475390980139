<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<CustomerMagicMails v-if="hasAccessToModule('magic-mail')" :company="company"></CustomerMagicMails>
			<template v-if="!userRoles.isGuest">
				<component :is="plugin" v-for="(plugin, index) in plugins" :key="index" />
			</template>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerDetailsAutomation',
	components: {
		CustomerMagicMails: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/CustomerMagicMails.vue')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	computed: {
		...mapState({
			userRoles: state => state.company.userRoles
		}),
		plugins: function () {
			return this.service.getAutomationsPlugins()
		}
	},
	methods: {
		hasAccessToModule: function (moduleName) {
			return this.appService.hasModule(moduleName)
		}
	}
}
</script>
